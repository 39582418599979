import React from "react"
import { detectMob } from "@src/Helper"

const ContactUsEmail = ({
  emailRef,
  email,
  isInputEmpty,
  isEmptyObject,
  error,
}) => {
  if (detectMob()) {
    return (
      <div className="contact-us__form__email">
        <label
          htmlFor="inputEmail"
          className="contact-us__form__email__label label-sm"
        >
          E-mail
        </label>
        <input
          type="text"
          className="contact-us__form__email__input input-md"
          id="inputEmail"
          spellCheck={false}
          placeholder="Masukkan alamat e-mail kamu"
          ref={emailRef}
          value={email.value}
          onChange={e => {
            isInputEmpty(emailRef)
            email.setValue(e.target.value)
          }}
        />
        {isEmptyObject(error.value, error.value.email) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.email}</span>
        )}
      </div>
    )
  } else {
    return (
      <div className="contact-us__form__email">
        <label
          htmlFor="inputEmail"
          className="contact-us__form__email__label label-sm"
        >
          E-mail
        </label>
        <input
          type="text"
          className="contact-us__form__email__input input-md"
          id="inputEmail"
          spellCheck={false}
          placeholder="Masukkan alamat e-mail kamu"
          ref={emailRef}
          value={email.value}
          onChange={e => {
            isInputEmpty(emailRef)
            email.setValue(e.target.value)
          }}
        />
        {isEmptyObject(error.value, error.value.email) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.email}</span>
        )}
      </div>
    )
  }
}

export default ContactUsEmail
