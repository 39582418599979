import * as React from "react"
import ContactUs from "@components/ContactUs/ContactUs"
import Seo from "@components/seo"

const ContactUsPage = () => (
  <>
    <Seo title="Contact Us" />
    <ContactUs />
  </>
)

export default ContactUsPage
