import { httpClient as client } from "./client"

export const submitMail = (name, email, message) => {
  return new Promise((resolve, reject) => {
    client
      .post("mailbox", {
        name,
        email,
        message,
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}
