import React from "react"
import { detectMob } from "@src/Helper"

const ContactUsName = ({
  nameRef,
  name,
  isInputEmpty,
  isEmptyObject,
  error,
  autofocus,
}) => {
  if (detectMob()) {
    return (
      <div className="contact-us__form__name">
        <label
          htmlFor="inputName"
          className="contact-us__form__name__label label-sm"
        >
          Nama
        </label>
        <input
          type="text"
          className="contact-us__form__name__input input-md"
          id="inputName"
          spellCheck={false}
          placeholder="Masukkan nama kamu"
          ref={nameRef}
          value={name.value}
          autoFocus={autofocus}
          onChange={e => {
            isInputEmpty(nameRef)
            name.setValue(e.target.value)
          }}
        />
        {isEmptyObject(error.value, error.value.name) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.name}</span>
        )}
      </div>
    )
  } else {
    return (
      <div className="contact-us__form__name">
        <label
          htmlFor="inputName"
          className="contact-us__form__name__label label-sm"
        >
          Nama
        </label>
        <input
          type="text"
          className="contact-us__form__name__input input-md"
          id="inputName"
          spellCheck={false}
          placeholder="Masukkan nama kamu"
          ref={nameRef}
          value={name.value}
          autoFocus={autofocus}
          onChange={e => {
            isInputEmpty(nameRef)
            name.setValue(e.target.value)
          }}
        />
        {isEmptyObject(error.value, error.value.name) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.name}</span>
        )}
      </div>
    )
  }
}

export default ContactUsName
