import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Icon } from "@iconify/react"
import { submitMail } from "@http/MailboxRepository"
import { detectMob, useInput, isInputEmpty, isEmptyObject } from "@src/Helper"
import { AlertSuccess } from "@components/utils/Alert"
import ContactUsInputName from "./ContactUsInputName"
import ContactUsInputMessage from "./ContactUsInputMessage"
import ContactUsInputEmail from "./ContactUsInputEmail"

const ContactUs = () => {
  // Hooks
  const emailRef = useRef(null)
  const nameRef = useRef(null)
  const messageRef = useRef(null)
  const email = useInput("")
  const name = useInput("")
  const message = useInput("")
  const successInfo = useInput("")
  const isLoading = useInput(false)
  const error = useInput({})

  // Custom Functions
  const handleSumbitMail = () => {
    error.setValue({ ...error.value })
    isLoading.setValue(true)
    submitMail(name.value, email.value, message.value)
      .then(res => {
        isLoading.setValue(false)
        error.setValue({})
        successInfo.setValue(res.data.message)
        name.setValue("")
        email.setValue("")
        message.setValue("")
      })
      .catch(err => {
        if (err.response.data.status_code === 422) {
          error.setValue({
            ...error.value,
            name: err.response.data.errors.name
              ? err.response.data.errors.name
              : null,
            email: err.response.data.errors.email
              ? err.response.data.errors.email
              : null,
            message: err.response.data.errors.message
              ? err.response.data.errors.message
              : null,
          })
        }
        successInfo.setValue("")
        isLoading.setValue(false)
      })
  }

  // JSX
  if (detectMob()) {
    return (
      <div id="m-contactUs" className="contact-us">
        <div className="contact-us__jumbotron">
          <div className="container pad-t-175 pad-b-100">
            <h1 className="contact-us__jumbotron__title heading-1">
              Hubungi Kami
            </h1>
          </div>
        </div>
        <div className="container pad-t-30 pad-b-70">
          <div className="row">
            <div className="col-12 mar-b-50">
              {successInfo.value !== "" && (
                <AlertSuccess
                  className="flex"
                  text={
                    <p className="body mb-0 flex-a-center">
                      {successInfo.value}
                    </p>
                  }
                />
              )}
              <h2 className="contact-us__form__title heading-3">
                Sampaikan Pesanmu
              </h2>
              <p className="contact-us__form__description body">
                Kirimkan pesan kamu melalui form berikut ini.
              </p>
              <form
                className="contact-us__form"
                onSubmit={e => {
                  e.preventDefault()
                  handleSumbitMail()
                }}
              >
                <ContactUsInputName
                  nameRef={nameRef}
                  name={name}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                  autofocus={true}
                />
                <ContactUsInputEmail
                  emailRef={emailRef}
                  email={email}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                />
                <ContactUsInputMessage
                  messageRef={messageRef}
                  message={message}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                />
                <button
                  type="submit"
                  className="contact-us__form__button button button-lg button-primary"
                  disabled={isLoading.value}
                >
                  {isLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Kirim Pesan</>
                  )}
                </button>
              </form>
            </div>
            <div className="col-12">
              <div className="contact-us__card">
                <h3 className="contact-us__card__title heading-5 text-700">
                  Temukan Kami
                </h3>
                <p className="contact-us__card__description body">
                  Kami hadir di berbagai platform media sosial untuk mempermudah
                  kamu menemukan dan menghubungi kami.
                </p>
                <div className="contact-us__social-media flex-a-center">
                  <a
                    className="contact-us__social-media__button"
                    href="https://www.instagram.com/sociopipe/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      className="contact-us__social-media__button__img"
                      src="../../images/contact/instagram-icon.svg"
                      alt="Instagram Button"
                      width={19.22}
                      height={19.22}
                    />
                  </a>
                  <a
                    className="contact-us__social-media__button"
                    href="https://www.linkedin.com/company/sociopipe/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      className="contact-us__social-media__button__img"
                      src="../../images/contact/linkedin-icon.svg"
                      alt="Linkedin Button"
                      width={18.12}
                      height={18.71}
                    />
                  </a>
                  <a
                    className="contact-us__social-media__button"
                    href="https://twitter.com/sociopipe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      className="contact-us__social-media__button__img"
                      src="../../images/contact/twitter-icon.svg"
                      alt="Twitter Button"
                      width={22.12}
                      height={17.99}
                    />
                  </a>
                  <a
                    className="contact-us__social-media__button"
                    href="https://www.facebook.com/sociopipe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      className="contact-us__social-media__button__img"
                      src="../../images/contact/facebook-icon.svg"
                      alt="Facebook Button"
                      width={11.08}
                      height={22.77}
                    />
                  </a>
                  <a
                    className="contact-us__social-media__button"
                    href="https://www.tiktok.com/@sociopipe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon icon="simple-icons:tiktok" width="20" height="20" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="contact-us">
        <div className="contact-us__jumbotron">
          <div className="container pad-t-175 pad-b-100">
            <h1 className="contact-us__jumbotron__title heading-1">
              Hubungi Kami
            </h1>
          </div>
        </div>
        <div className="container pad-t-50 pad-b-70">
          <div className="row">
            <div className="col-6 pe-4">
              <div className="contact-us__card">
                <h3 className="contact-us__card__title heading-5 text-700">
                  Temukan Kami
                </h3>
                <p className="contact-us__card__description body">
                  Kami hadir di berbagai platform media sosial untuk mempermudah
                  kamu menemukan dan menghubungi kami.
                </p>
                <div className="contact-us__social-media flex-a-center">
                  <a
                    className="contact-us__social-media__button"
                    href="https://www.instagram.com/sociopipe/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      className="contact-us__social-media__button__img"
                      src="../../images/contact/instagram-icon.svg"
                      alt="Instagram Button"
                      width={19.22}
                      height={19.22}
                    />
                  </a>
                  <a
                    className="contact-us__social-media__button"
                    href="https://www.linkedin.com/company/sociopipe/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      className="contact-us__social-media__button__img"
                      src="../../images/contact/linkedin-icon.svg"
                      alt="Linkedin Button"
                      width={18.12}
                      height={18.71}
                    />
                  </a>
                  <a
                    className="contact-us__social-media__button"
                    href="https://twitter.com/sociopipe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      className="contact-us__social-media__button__img"
                      src="../../images/contact/twitter-icon.svg"
                      alt="Twitter Button"
                      width={22.12}
                      height={17.99}
                    />
                  </a>
                  <a
                    className="contact-us__social-media__button"
                    href="https://www.facebook.com/sociopipe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      className="contact-us__social-media__button__img"
                      src="../../images/contact/facebook-icon.svg"
                      alt="Facebook Button"
                      width={11.08}
                      height={22.77}
                    />
                  </a>
                  <a
                    className="contact-us__social-media__button"
                    href="https://www.tiktok.com/@sociopipe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon icon="simple-icons:tiktok" width="20" height="20" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 ps-4">
              {successInfo.value !== "" && (
                <AlertSuccess
                  className="flex-a-center"
                  text={
                    <p className="body mb-0 flex-a-center">
                      {successInfo.value}
                    </p>
                  }
                />
              )}
              <h2 className="contact-us__form__title heading-3">
                Sampaikan Pesanmu
              </h2>
              <p className="contact-us__form__description body">
                Kirimkan pesan kamu melalui form berikut ini.
              </p>
              <form
                className="contact-us__form"
                onSubmit={e => {
                  e.preventDefault()
                  handleSumbitMail()
                }}
              >
                <ContactUsInputName
                  nameRef={nameRef}
                  name={name}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                  autofocus={true}
                />
                <ContactUsInputEmail
                  emailRef={emailRef}
                  email={email}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                />
                <ContactUsInputMessage
                  messageRef={messageRef}
                  message={message}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                />
                <button
                  type="submit"
                  className="contact-us__form__button button button-lg button-primary"
                  disabled={isLoading.value}
                >
                  {isLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Kirim Pesan</>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactUs
