import React from "react"
import { detectMob } from "@src/Helper"

const ContactUsMessage = ({
  messageRef,
  message,
  isInputEmpty,
  isEmptyObject,
  error,
}) => {
  if (detectMob()) {
    return (
      <div className="contact-us__form__message">
        <label
          htmlFor="inputMessage"
          className="contact-us__form__message__label label-sm"
        >
          Apa yang ingin kamu sampaikan?
        </label>
        <textarea
          className="contact-us__form__message__input textarea-md"
          id="inputMessage"
          rows="4"
          spellCheck={false}
          placeholder="Masukkan pesan kamu disini"
          ref={messageRef}
          value={message.value}
          onChange={e => {
            isInputEmpty(messageRef)
            message.setValue(e.target.value)
          }}
        />
        {isEmptyObject(error.value, error.value.message) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.message}</span>
        )}
      </div>
    )
  } else {
    return (
      <div className="contact-us__form__message">
        <label
          htmlFor="inputMessage"
          className="contact-us__form__message__label label-sm"
        >
          Apa yang ingin kamu sampaikan?
        </label>
        <textarea
          className="contact-us__form__message__input textarea-md"
          id="inputMessage"
          rows="4"
          spellCheck={false}
          placeholder="Masukkan pesan kamu disini"
          ref={messageRef}
          value={message.value}
          onChange={e => {
            isInputEmpty(messageRef)
            message.setValue(e.target.value)
          }}
        />
        {isEmptyObject(error.value, error.value.message) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.message}</span>
        )}
      </div>
    )
  }
}

export default ContactUsMessage
